import { type MetaFunction } from '@remix-run/node'
import { Button } from '#app/components/ui/button.js'

export const meta: MetaFunction = () => [{ title: 'Epic Notes' }]

export default function Index() {
	return (
		<main className="font-poppins flex h-full place-items-center items-center">
			<div className="container mt-12 flex flex-col items-center justify-center">
				<p className="mb-8 text-center text-4xl font-bold">Tryggsurf</p>
				<p className="mb-8 text-center text-lg text-muted-foreground">
					{' '}
					Salesportal
				</p>
				<Button>Skapa order</Button>
			</div>
		</main>
	)
}
